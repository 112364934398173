import TextColorLogo from './TextColorLogo.png';
import MockUp from './Mockup.png'
import './App.css';
import React, { useState } from "react";
import { Button, TextField } from '@mui/material';
import { fontFamily } from '@mui/system';
import { createTheme, ThemeProvider, makeStyles } from '@mui/material';
import { ClassNames } from '@emotion/react';

const theme = createTheme({
  typography: {
    fontFamily: '"Lato", sans-serif',
  },
  palette: {
    primary: {
      main: '#FB581D'
    },
    secondary: {
      main: '#FEE8DF'
    }
  }
})

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={TextColorLogo} className="App-logo" alt="logo" />
        <p className="App-subheader">
          Fitness + friends = 🔥
        </p>
        <div className='App-form'>
        <ThemeProvider theme={theme}>
        <div className="TextField-without-border-radius">
        <TextField sx={{
          input: {
            color: '#303030'
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#D9D9D9"
            },
            '&:hover fieldset': {
              borderColor: '#D9D9D9'
            }
          },
          width: 200,
          color: '#D9D9D9',
          fontFamily: 'Lato',
          borderRadius: 100
        }}size='small' color='grey' id="outlined-basic" label="Enter your email" variant="outlined"/>
        </div>
        <Button  sx={{
          ':hover': {
            bgcolor: '#FB581D',
            color: '#fff',
          },
          color: '#FB581D',
          fontWeight: 'bold',
          textTransform:'none',
          borderRadius: 2,
          marginLeft: 2
        }} variant='contained' color='secondary' disableElevation>Join Waitlist</Button>
        </ThemeProvider>
        </div>
      </header>
      <img src={MockUp} className="App-mockup"/>
    </div>
  );
}

export default App;